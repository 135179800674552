import { useChainId, useSwitchChain } from 'wagmi';
import { useWallet } from './useWallet';
import { useSignals } from '@preact/signals-react/runtime';
import { sSelectedChain } from '@signals/chains';

interface Props {
  chainId?: number;
}

export const useAuthGuard = ({ chainId }: Props) => {
  useSignals();
  const { connect, wallet } = useWallet();
  const { switchChainAsync } = useSwitchChain();
  const selectedChainId = useChainId();

  const isDisconnected = !Boolean(wallet?.address);

  chainId = chainId ?? sSelectedChain.value?.id;

  const isWrongChain = () => {
    if (
      selectedChainId !== chainId &&
      selectedChainId !== undefined &&
      chainId !== undefined
    ) {
      return true;
    }
    return false;
  };

  const hasSignature = () => {
    return wallet?.isSignedIn();
  };

  const ensureAuth = async () => {
    if (isDisconnected) {
      connect();
      return false;
    }

    if (!hasSignature()) {
      await wallet?.signIn();
      if (!hasSignature()) {
        return false;
      }
    }

    if (isWrongChain()) {
      await switchChainAsync({
        chainId: chainId!,
      });
      if (isWrongChain()) {
        return false;
      }
    }

    return true;
  };

  const isFullyConnected = () => {
    return Boolean(!isDisconnected && hasSignature() && !isWrongChain());
  };

  return {
    ensureAuth,
    isFullyConnected,
  };
};
