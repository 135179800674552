/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as CommonV1Common from "../../common/v1/common.pb";
import * as Creator_tokenV2Creator_token from "../../creator_token/v2/creator_token.pb";
import * as Payment_processorV2Payment_processor from "../../payment_processor/v2/payment_processor.pb";

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (keyof T extends infer K
      ? K extends string & keyof T
        ? { [k in K]: T[K] } & Absent<T, K>
        : never
      : never);

export enum BidType {
  ITEM = "ITEM",
  COLLECTION = "COLLECTION",
  TOKENSET = "TOKENSET",
}

export enum ActivityType {
  INVALID_ACTIVITY_TYPE = "INVALID_ACTIVITY_TYPE",
  CREATE_OFFER = "CREATE_OFFER",
  CREATE_LISTING = "CREATE_LISTING",
  ORDER_ACCEPTED = "ORDER_ACCEPTED",
  ORDER_CANCELED = "ORDER_CANCELED",
  TOKEN_TRANSFER = "TOKEN_TRANSFER",
  TOKEN_MINT = "TOKEN_MINT",
  TOKEN_UPDATE = "TOKEN_UPDATE",
  COLLECTION_UPDATE = "COLLECTION_UPDATE",
  UPDATE_OFFER = "UPDATE_OFFER",
  UPDATE_LISTING = "UPDATE_LISTING",
  LISTING_CANCELED = "LISTING_CANCELED",
  OFFER_CANCELED = "OFFER_CANCELED",
}

export enum ImageFit {
  COVER = "COVER",
  CONTAIN = "CONTAIN",
}

export enum ChainType {
  UNKNOWN_CHAIN_TYPE = "UNKNOWN_CHAIN_TYPE",
  MAINNET = "MAINNET",
  POLYGON = "POLYGON",
  SEPOLIA = "SEPOLIA",
  OPTIMISM = "OPTIMISM",
  XAI = "XAI",
  ARBITRUM = "ARBITRUM",
}

export enum Orderbook {
  LIMIT_BREAK = "LIMIT_BREAK",
  RESERVOIR = "RESERVOIR",
}

export enum OrderType {
  INVALID_ORDER_TYPE = "INVALID_ORDER_TYPE",
  ASK = "ASK",
  ITEM_OFFER = "ITEM_OFFER",
  COLLECTION_OFFER = "COLLECTION_OFFER",
  TOKENSET_OFFER = "TOKENSET_OFFER",
}

export enum OrderKind {
  PAYMENT_PROCESSOR_V2 = "PAYMENT_PROCESSOR_V2",
}

export enum OrderStatus {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
  FILLED = "FILLED",
  INVALID = "INVALID",
}

export enum OrderByField {
  CREATED_AT = "CREATED_AT",
  UPDATED_AT = "UPDATED_AT",
  EXPIRATION = "EXPIRATION",
  ITEM_PRICE = "ITEM_PRICE",
  AMOUNT = "AMOUNT",
  AMOUNT_FILLED = "AMOUNT_FILLED",
  VALID_AT = "VALID_AT",
  TOKEN_ID = "TOKEN_ID",
  SALE_PRICE = "SALE_PRICE",
  RARITY_SCORE = "RARITY_SCORE",
  RARITY_RANK = "RARITY_RANK",
}

export enum StepKind {
  TRANSACTION = "TRANSACTION",
  SIGNATURE = "SIGNATURE",
  CALL_PAYMENT_PROCESSOR = "CALL_PAYMENT_PROCESSOR",
  CALL_ERC721 = "CALL_ERC721",
  CALL_ERC20 = "CALL_ERC20",
}

export enum StepAction {
  INVALID_STEP_ACTION = "INVALID_STEP_ACTION",
  APPROVE_NFT_CONTRACT = "APPROVE_NFT_CONTRACT",
  AUTHORIZE_LISTING = "AUTHORIZE_LISTING",
  AUTHORIZE_OFFER = "AUTHORIZE_OFFER",
  INCREASE_ALLOWANCE = "INCREASE_ALLOWANCE",
  SIGN_PERMIT = "SIGN_PERMIT",
  BUY_LISTING = "BUY_LISTING",
  ACCEPT_OFFER = "ACCEPT_OFFER",
  CANCEL_LISTING = "CANCEL_LISTING",
  CANCEL_ORDER = "CANCEL_ORDER",
  SWEEP_COLLECTION = "SWEEP_COLLECTION",
  SWAP_CURRENCY = "SWAP_CURRENCY",
}

export enum StepItemStatus {
  INCOMPLETE = "INCOMPLETE",
  COMPLETE = "COMPLETE",
}

export enum SignatureKind {
  EIP712 = "EIP712",
}

export enum UniSwapVersion {
  INVALID_UNISWAP_VERSION = "INVALID_UNISWAP_VERSION",
  V2 = "V2",
  V3 = "V3",
}

export enum FeeKind {
  BASIC_FEE = "BASIC_FEE",
  MARKETPLACE_FEE = "MARKETPLACE_FEE",
  PLATFORM_FEE = "PLATFORM_FEE",
  ROYALTY_FEE = "ROYALTY_FEE",
}

export enum Mode {
  UNKNOWN_MODE = "UNKNOWN_MODE",
  DARK = "DARK",
  LIGHT = "LIGHT",
  VALERIA = "VALERIA",
}

export type Contract = {
  chainId?: string;
  address?: string;
  codeHash?: string;
  type?: CommonV1Common.ContractType;
  rps?: number;
  disabled?: boolean;
  creationTransactionHash?: string;
  creationTransactionTimestamp?: string;
  creatorAddress?: string;
  metadata?: ContractMetadata;
  createdAt?: string;
};

export type ContractMetadata = {
  baseUriOverride?: string;
  suffixUriOverride?: string;
};

export type MerkleRoot = {
  root?: string;
};

export type MerkleProof = {
  root?: string;
  siblings?: string[];
};

export type Cosignature = {
  signer?: string;
  taker?: string;
  expiration?: string;
  r?: string;
  s?: string;
  v?: number;
};

export type Activity = {
  id?: string;
  chainId?: string;
  collection?: string;
  type?: ActivityType;
  order?: Order;
  transfer?: Transfer;
  token?: TokenRef;
  fromAddress?: string;
  toAddress?: string;
  onChain?: TransactionMeta;
  isCancel?: boolean;
  price?: Price;
  externalId?: string;
  paymentMethod?: string;
  createdAt?: string;
};

export type TransactionMeta = {
  transactionHash?: string;
  blockNumber?: string;
  blockIndex?: string;
};

export type Transfer = {
  chainId?: string;
  collection?: string;
  transactionHash?: string;
  blockNumber?: string;
  tokenId?: string;
  fromAddress?: string;
  toAddress?: string;
  isMint?: boolean;
};

export type TokenRef = {
  ref?: string;
  chainId?: string;
  collection?: string;
  tokenId?: string;
  name?: string;
  imageUrl?: string;
  animationUrl?: string;
  imageUrlLarge?: string;
  imageUrlSmall?: string;
  description?: string;
  ownerAddress?: string;
  refHash?: string;
  isErc1155?: boolean;
};

export type CollectionRef = {
  ref?: string;
  chainId?: string;
  contractAddress?: string;
  type?: CommonV1Common.ContractType;
  name?: string;
  symbol?: string;
};

export type Token = {
  ref?: string;
  refHash?: string;
  chainId?: string;
  collection?: CollectionRef;
  tokenId?: string;
  ownerAddress?: string;
  imageUrl?: string;
  imageUrlLarge?: string;
  imageUrlSmall?: string;
  name?: string;
  description?: string;
  floorPrice?: Price;
  ceilingPrice?: Price;
  uri?: string;
  rawMetadata?: { [key: string]: string };
  rarityRank?: string;
  rarityScore?: number;
  animationUrl?: string;
  attributes?: TokenAttributeValue[];
  stats?: TokenStats;
  openseaVerificationStatus?: string;
  isListed?: boolean;
  lastTransferTransactionHash?: string;
  lastTransferredAt?: string;
  isErc1155?: boolean;
  tokenOwnerErc1155?: TokenOwnerErc1155;
};

export type Erc1155Meta = {
  owners?: TokenOwnerErc1155[];
  totalSupply?: string;
};

export type TokenOwnerErc1155 = {
  chainId?: string;
  collection?: string;
  tokenId?: string;
  ownerAddress?: string;
  balance?: string;
  totalSupply?: string;
  listedBalance?: string;
};

export type CollectionConfig = {
  chainId?: string;
  collection?: string;
  slug?: string;
  name?: string;
  symbol?: string;
  imageBanner?: string;
  shortDescription?: string;
  longDescription?: string[];
  additionalImages?: string[];
  externalUrl?: string;
  twitter?: string[];
  discord?: string[];
  isHidden?: boolean;
  isDefault?: boolean;
  imageUrl?: string;
  additionalFees?: FeeOnTop[];
  flags?: CollectionFlag[];
  marketplaceId?: string;
  imageFit?: ImageFit;
  creationTxHash?: string;
  disabled?: boolean;
  baseUri?: string;
  suffixUri?: string;
  whitelistPaymentMethods?: string[];
};

export type Flag = {
  key?: string;
  value?: string;
  enabled?: boolean;
};

export type Collection = {
  ref?: string;
  chainId?: string;
  contractAddress?: string;
  type?: CommonV1Common.ContractType;
  ownerAddress?: string;
  name?: string;
  symbol?: string;
  baseUri?: string;
  suffixUri?: string;
  floorPrice?: Price;
  ceilingPrice?: Price;
  supportedPaymentMethods?: string[];
  paymentProcessorAddress?: string;
  paymentProcessorEncoderAddress?: string;
  paymentProcessorVersion?: Payment_processorV2Payment_processor.PaymentProcessorVersion;
  totalSupply?: string;
  royalties?: CommonV1Common.RoyaltyReceiver[];
  stats?: CollectionStats;
  mintedAt?: string;
  securityConfig?: CollectionSecurityConfig;
  creationTransactionHash?: string;
  creationTransactionTimestamp?: string;
  creatorAddress?: string;
  codeHash?: string;
  lastBlockNumber?: string;
  config?: CollectionConfig;
  createdAt?: string;
  updatedAt?: string;
};

export type CollectionStats = {
  topOffer?: Order;
  topOfferNext?: Order;
  floorOffer?: Order;
  topAsk?: Order;
  floorAsk?: Order;
  topSale?: Order;
  floorSale?: Order;
  lastSale?: Order;
  totalBids?: string;
  totalSales?: string;
  totalSalesOneDay?: string;
  totalListings?: string;
  ownerCount?: string;
  volume?: Volume;
  averages?: Averages;
};

export type Averages = {
  salePrice?: number;
  salePriceOneDay?: number;
  salePriceSevenDay?: number;
  salePriceThirtyDay?: number;
};

export type TokenStats = {
  topOffer?: Order;
  floorAsk?: Order;
  floorAskBuy?: Order;
  topSale?: Order;
  lastSale?: Order;
  totalBids?: string;
  totalSales?: string;
  totalListings?: string;
  ownerCount?: string;
  listedItems?: string;
  salesByPaymentMethod?: { [key: string]: string };
  averageSalePrice?: Price;
};

export type CollectionSecurityConfig = {
  transferSecurityLevel?: Creator_tokenV2Creator_token.TransferSecurityLevels;
  transferValidator?: string;
  operatorWhitelist?: string[];
  operatorBlacklist?: string[];
  codehashWhitelist?: string[];
  codehashBlacklist?: string[];
  bannedAccounts?: string[];
  trustedChannels?: string[];
};

export type Chain = {
  type?: ChainType;
  id?: string;
  name?: string;
  stats?: ChainStats;
  blockExplorerName?: string;
  blockExplorerUrl?: string;
  coingeckoId?: string;
  defaultRpcUrl?: string;
  icon?: Icon;
  currencies?: Currency[];
};

export type Icon = {
  small?: string;
  large?: string;
  standard?: string;
};

export type Chains = {
  chains?: { [key: string]: Chain };
};

export type ChainStats = {
  currentPrice?: number;
  marketCap?: string;
  marketCapRank?: string;
  fullyDilutedValuation?: string;
  totalVolume?: string;
  high24H?: number;
  low24H?: number;
  priceChange24H?: number;
  priceChangePercentage24H?: number;
  marketCapChange24H?: number;
  marketCapChangePercentage24H?: number;
  circulatingSupply?: number;
  totalSupply?: number;
  maxSupply?: number;
  ath?: number;
  athChangePercentage?: number;
  athDate?: string;
  atl?: number;
  atlChangePercentage?: number;
  atlDate?: string;
  lastUpdated?: string;
};

export type Price = {
  amount?: Amount;
  currency?: Currency;
  netAmount?: Amount;
};

export type Amount = {
  decimal?: number;
  raw?: string;
  usd?: number;
  native?: number;
};

export type Currency = {
  chainId?: string;
  symbol?: string;
  name?: string;
  icon?: string;
  address?: string;
  decimals?: number;
  coinGeckoId?: string;
  isWrapped?: boolean;
  isWrappedNative?: boolean;
};

export type Order = {
  id?: string;
  chainId?: string;
  type?: OrderType;
  orderbook?: Orderbook;
  kind?: OrderKind;
  externalId?: string;
  protocol?: Payment_processorV2Payment_processor.OrderProtocol;
  cosigner?: string;
  collection?: string;
  maker?: string;
  beneficiary?: string;
  marketplace?: string;
  fallbackRoyaltyRecipient?: string;
  paymentMethod?: string;
  token?: TokenRef;
  tokenSet?: TokenSetItem[];
  amount?: string;
  amountFilled?: string;
  price?: Price;
  marketplaceFeeNumerator?: string;
  maxRoyaltyFeeNumerator?: string;
  nonce?: string;
  masterNonce?: string;
  signature?: string;
  status?: OrderStatus;
  useOffChainCancellation?: boolean;
  paymentProcessorVersion?: Payment_processorV2Payment_processor.PaymentProcessorVersion;
  expiration?: string;
  digest?: string;
  validAt?: string;
  source?: OrderSource;
  additionalFees?: FeeOnTop[];
  labels?: { [key: string]: string };
  taker?: string;
  takerPrice?: Price;
  takerAmount?: string;
  canceledAt?: string;
  sale?: OrderSale;
  cancel?: OrderCancel;
  amountRemaining?: string;
  attributes?: CommonV1Common.TokenAttributeFilter[];
  createdAt?: string;
  updatedAt?: string;
};

export type OrderSale = {
  price?: Price;
  transactionHash?: string;
  beneficiary?: string;
  timestamp?: string;
};

export type OrderCancel = {
  transactionHash?: string;
  timestamp?: string;
};

export type OrderBy = {
  field?: OrderByField;
  desc?: boolean;
};

export type TokenSetItem = {
  token?: TokenRef;
  merkleProof?: MerkleProof;
};

export type Steps = {
  steps?: Step[];
};

export type Step = {
  action?: StepAction;
  description?: string;
  kind?: StepKind;
  items?: StepItem[];
};

export type StepItem = {
  status?: StepItemStatus;
  orderIndex?: number;
  data?: StepData;
};

export type StepData = {
  chainId?: string;
  approveNftContract?: StepApproveNftContract;
  sign?: StepSign;
  buyListing?: StepBuyListing;
  buyListings?: StepBuyListings;
  acceptOffers?: StepAcceptOffers;
  revokeNonce?: StepRevokeNonce;
  sweepCollection?: StepSweepCollection;
  addAllowance?: StepAddAllowance;
  wrapCurrency?: StepWrapCurrency;
  swapCurrency?: StepSwapCurrency;
  unwrapCurrency?: StepUnwrapCurrency;
};

export type StepUnwrapCurrency = {
  contractAddress?: string;
  functionName?: string;
  amount?: string;
};

export type StepSwapCurrency = {
  tokenIn?: string;
  tokenOut?: string;
  fee?: number;
  recipient?: string;
  deadline?: string;
  amountOut?: string;
  amountInMaximum?: string;
  sqrtPriceLimitX96?: string;
  contractAddress?: string;
  functionName?: string;
  uniswapVersion?: UniSwapVersion;
  value?: string;
};

export type StepWrapCurrency = {
  paymentMethod?: string;
  amount?: string;
};

export type StepApproveNftContract = {
  contractAddress?: string;
  tokenId?: string;
  operator?: string;
};

export type StepAddAllowance = {
  contractAddress?: string;
  amount?: string;
  operator?: string;
  maxAmount?: string;
};

export type StepSweepCollection = {
  chainId?: string;
  collection?: string;
  items?: Order[];
  call?: EncodedPaymentProcessorWrite;
};

export type StepBuyListings = {
  items?: Order[];
  call?: EncodedPaymentProcessorWrite;
  takerOptions?: TakerOptions;
};

export type StepBuyListing = {
  order?: Order;
  call?: EncodedPaymentProcessorWrite;
  takerOptions?: TakerOptions;
};

export type StepAcceptOffers = {
  items?: Order[];
  call?: EncodedPaymentProcessorWrite;
  takerOptions?: TakerOptions;
};

type BaseStepSign = {
  signatureKind?: SignatureKind;
  domain?: Eip712Domain;
  types?: SignatureTypes;
  primaryType?: string;
  orderId?: string;
  collection?: string;
};

export type StepSign = BaseStepSign &
  OneOf<{
    saleApproval: SaleApprovalValue;
    itemOfferApproval: ItemOfferApprovalValue;
    collectionOfferApproval: CollectionOfferApprovalValue;
    tokenSetOfferApproval: TokenSetOfferApprovalValue;
  }>;

export type Eip712Domain = {
  name?: string;
  version?: string;
  chainId?: string;
  verifyingContract?: string;
};

export type AbiField = {
  name?: string;
  type?: string;
};

export type SignatureTypes = {
  saleApproval?: AbiField[];
  itemOfferApproval?: AbiField[];
  collectionOfferApproval?: AbiField[];
  tokenSetOfferApproval?: AbiField[];
};

export type SaleApprovalValue = {
  protocol?: number;
  cosigner?: string;
  seller?: string;
  marketplace?: string;
  fallbackRoyaltyRecipient?: string;
  paymentMethod?: string;
  tokenAddress?: string;
  tokenId?: string;
  amount?: string;
  itemPrice?: string;
  expiration?: string;
  marketplaceFeeNumerator?: string;
  maxRoyaltyFeeNumerator?: string;
  nonce?: string;
  masterNonce?: string;
};

export type CollectionFlag = {
  key?: string;
  value?: string;
  enabled?: boolean;
};

export type ItemOfferApprovalValue = {
  protocol?: number;
  cosigner?: string;
  buyer?: string;
  beneficiary?: string;
  marketplace?: string;
  fallbackRoyaltyRecipient?: string;
  paymentMethod?: string;
  tokenAddress?: string;
  tokenId?: string;
  amount?: string;
  itemPrice?: string;
  expiration?: string;
  marketplaceFeeNumerator?: string;
  nonce?: string;
  masterNonce?: string;
};

export type CollectionOfferApprovalValue = {
  protocol?: number;
  cosigner?: string;
  buyer?: string;
  beneficiary?: string;
  marketplace?: string;
  fallbackRoyaltyRecipient?: string;
  paymentMethod?: string;
  tokenAddress?: string;
  amount?: string;
  itemPrice?: string;
  expiration?: string;
  marketplaceFeeNumerator?: string;
  nonce?: string;
  masterNonce?: string;
};

export type TokenSetOfferApprovalValue = {
  protocol?: number;
  cosigner?: string;
  buyer?: string;
  beneficiary?: string;
  marketplace?: string;
  fallbackRoyaltyRecipient?: string;
  paymentMethod?: string;
  tokenAddress?: string;
  amount?: string;
  itemPrice?: string;
  expiration?: string;
  marketplaceFeeNumerator?: string;
  nonce?: string;
  masterNonce?: string;
  tokenSetMerkleRoot?: string;
};

export type FeeOnTop = {
  recipient?: string;
  bps?: number;
  amount?: string;
  kind?: FeeKind;
};

export type EncodedPaymentProcessorWrite = {
  contractAddress?: string;
  functionName?: string;
  encodedCalldata?: string;
  value?: string;
};

export type StepRevokeNonce = {
  nonce?: string;
  call?: EncodedPaymentProcessorWrite;
};

export type TokenAttribute = {
  key?: string;
  values?: TokenAttributeValue[];
};

export type TokenAttributeValue = {
  key?: string;
  value?: string;
  count?: string;
  percentage?: number;
  floorAsk?: Order;
};

export type OrderSource = {
  name?: string;
  domain?: string;
  logoUrl?: string;
  iconUrl?: string;
};

export type MarketplaceConfig = {
  id?: string;
  domain?: string;
  slug?: string;
  collections?: CollectionConfig[];
  themeOverrides?: ThemeOverrides;
  mode?: Mode;
  imageBanner?: string;
  imageLogo?: string;
  name?: string;
  description?: string;
  favicon?: string;
  meta?: MarketplaceMeta;
  socialLinks?: SocialLinks;
  hostnames?: string[];
  footer?: MarketplaceFooter;
  customAuth?: MarketplaceCustomAuth;
  resourceLinks?: MarketplaceResourceLinks;
  additionalImages?: { [key: string]: string };
  flags?: { [key: string]: boolean };
};

export type MarketplaceResourceLinks = {
  tos?: string;
  pp?: string;
};

export type MarketplaceCustomAuth = {
  thirdWeb?: ThirdWebConfig;
};

export type ThirdWebConfig = {
  clientId?: string;
  ecosystemWalletId?: string;
  partnerId?: string;
  authStrategy?: string;
  defaultChainId?: string;
};

export type MarketplaceFooter = {
  imageUrl?: string;
};

export type MarketplaceMeta = {
  title?: string;
  description?: string;
  domain?: string;
  image?: string;
};

export type SocialLinks = {
  twitter?: string;
  discord?: string;
  website?: string;
  instagram?: string;
  facebook?: string;
  telegram?: string;
  youtube?: string;
};

export type ThemeOverrides = {
  font?: string;
  backgroundColor?: string;
  primaryColor?: string;
  secondaryColor?: string;
};

export type Volume = {
  oneDay?: number;
  thirtyDay?: number;
  sevenDay?: number;
  allTime?: number;
};

export type VolumeChange = {
  nroneDay?: number;
  nrthirtyDay?: number;
  nrsevenDay?: number;
};

export type TakerOptions = {
  paymentMethod?: string;
  beneficiary?: string;
};

export type PriceFilter = {
  currencySymbol?: string;
  rawValue?: string;
  decimalValue?: number;
};

export type CurrencyFilter = {
  currencySymbols?: string[];
  listingsOnly?: boolean;
  offersOnly?: boolean;
};

export type Timeseries = {
  series?: CommonV1Common.PointTime[];
};
