import { mainnet, goerli } from 'wagmi/chains';
import { zeroAddress } from 'viem';
import * as allChains from 'wagmi/chains';
import { getChainById, sSelectedChain } from '@signals/chains';
import { DefaultChain } from '@api/utils/chains';
import { useSignals } from '@preact/signals-react/runtime';

export default function useChainCurrency(chainId?: number) {
  useSignals();
  const chain = getChainById(
    chainId || sSelectedChain.value?.id || DefaultChain.id,
  );
  return getChainCurrency(chain?.id as number);
}

// this is a hack to patch the nativeCurrency for Polygon
// because wagmi has not updated the chain yet
function patchMatic(nativeCurrency: typeof DefaultChain.nativeCurrency) {
  if (nativeCurrency.symbol === 'MATIC') {
    return {
      ...nativeCurrency,
      name: 'POL',
      symbol: 'POL',
    };
  }
  return nativeCurrency;
}

export function getChainCurrency(chainId: number) {
  const chains = Object.values({ ...allChains });
  let chain = chains.find((chain) => chainId === chain.id);
  const ETHChains: number[] = [mainnet.id, goerli.id];

  if (!chain || !chain.nativeCurrency || ETHChains.includes(chain.id)) {
    return {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      address: zeroAddress,
      chainId: chain?.id.toString() || mainnet.id.toString(),
    };
  } else {
    return {
      ...patchMatic(chain.nativeCurrency),
      address: zeroAddress,
      chainId: chain.id.toString(),
    };
  }
}
