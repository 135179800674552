import useSWR from 'swr/immutable';
type CoinList = Record<string, { name: string; symbol: string; id: string }[]>;

export default function useCoinIds() {
  const baseUrl = 'https://api.coingecko.com/api/v3/coins/list';
  const response = useSWR(baseUrl, null, {
    refreshInterval: 7200000, //2hr
  });
  const coins: CoinList =
    response?.data?.reduce((coins: CoinList, coin: CoinList[0][0]) => {
      //Hardcoded symbol to id pairings for established cryptocurrencies
      if (
        (coin.symbol === 'eth' && coin.id !== 'ethereum') ||
        (coin.symbol === 'weth' && coin.id !== 'weth') ||
        (coin.symbol === 'wpol' && coin.id !== 'wpol') ||
        (coin.symbol === 'usdc' && coin.id !== 'usd-coin') ||
        (coin.symbol === 'pol' && coin.id !== 'pol-ex-matic') ||
        (coin.symbol === 'dai' && coin.id !== 'dai') ||
        (coin.symbol === 'sand' && coin.id !== 'the-sandbox')
      ) {
        return coins;
      }
      if (!coins[coin.symbol]) {
        coins[coin.symbol] = [coin];
      } else {
        coins[coin.symbol].push(coin);
      }
      return coins;
    }, {}) || {};

  return {
    ...response,
    data: coins,
  };
}
