import { MarketplaceConfig, Mode } from '@api/orderbook_api/v1/types.pb';
import { computed, signal } from '@preact/signals-react';

export const loadMarketplace = (config: MarketplaceConfig) => {
  __marketplace.value = config ?? defaultMarketplaceConfig;
};

const __marketplace = signal<undefined | null | MarketplaceConfig>(undefined);

export const sMarketplace = computed(() => {
  return __marketplace.value;
});

export function getCollectionConfig(contractAddress: string) {
  return sMarketplace.value?.collections?.find(
    (c) => c.collection?.toLowerCase() === contractAddress.toLowerCase(),
  );
}

const defaultMarketplaceConfig: MarketplaceConfig = {
  id: 'd5c445a9-a5ca-4e84-9866-e4be22873204',
  domain: 'valeriagames',
  slug: 'valeriagames',
  collections: [
    {
      chainId: '137',
      collection: '0x7f5d6f2a56db63dfa86cf4d45a2a0c745768f5a4',
      slug: 'polygon-erc721c-2',
      symbol: 'PolygonERC721C2',
      imageBanner:
        'https://img.reservoir.tools/images/v2/polygon/i9YO%2F4yHXUdJsWcTqhqvf3RzkzXXgzVoWVvGzVSYHiynUEzLEeJINZ7d%2B9YYSDLimqcPMSep36Dj6rlI1sXG5j1vvHrsidxGuLbezPtqRy58NBZuHCOQzOKtnDjArBPN?width=250',
      imageUrl:
        'https://img.reservoir.tools/images/v2/polygon/i9YO%2F4yHXUdJsWcTqhqvf3RzkzXXgzVoWVvGzVSYHiynUEzLEeJINZ7d%2B9YYSDLimqcPMSep36Dj6rlI1sXG5j1vvHrsidxGuLbezPtqRy58NBZuHCOQzOKtnDjArBPN?width=250',
      marketplaceId: 'd5c445a9-a5ca-4e84-9866-e4be22873204',
      additionalImages: [
        'https://img.reservoir.tools/images/v2/polygon/i9YO%2F4yHXUdJsWcTqhqvf%2FOr6FAgZDlPIg7jJ3M7OuO4h%2BmqXMx8tUb%2B2i3HWPYblO1sfCfbYnjsvP9SJtNz7QbPqR33QADj3%2BcZQ%2BN%2Flbk%3D',
        'https://img.reservoir.tools/images/v2/polygon/i9YO%2F4yHXUdJsWcTqhqvf4P4%2F4OBPw5iU7jBO4BC5qT4CPa8%2BEFHpcN5eThYEW2gdidB26rWY2Z386D76Tcw6AHpfnljFKn%2BV80JsJy1QKw%3D',
        'https://img.reservoir.tools/images/v2/polygon/i9YO%2F4yHXUdJsWcTqhqvfw65I9NbebwlJ5x1%2BNsTqXkEJDpHKRruG0X75F54sqbQw2YaxDVk78fdJb%2BRC5h50tpqq5S8zqrxTOmZTvNKgdA%3D',
        'https://img.reservoir.tools/images/v2/polygon/i9YO%2F4yHXUdJsWcTqhqvf7GdNIX6is4cvl%2FY2e9fDFi6sxRUnz1wIAd%2B42W8333u4WhxVJTUTv%2BFbiPibODPy3%2FNyXXRTrP%2Fm9U%2Fd%2B7Gv7s%3D',
      ],
      flags: [{ key: 'reservoir_enabled', value: 'true', enabled: true }],
    },
    {
      chainId: '1',
      collection: '0x2187093a2736442d0b5c5d5464b98fc703e3b88d',
      slug: 'valeriagames',
      symbol: 'VOL',
      imageBanner:
        'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAI%2BnzPkECBTuIaOBSBU62KP26Y%2BBMfYJa%2BTNYAKZfzcGKM3nfdD4o7QSSdNiAsAP%2FuQxrBP5oL9M3DeexDQrGiqTNoSttZrmsPi68sIO3cwd',
      shortDescription:
        "Visit https://market.valeriagames.com/ to trade all Valeria Games NFT's.",
      additionalImages: [
        'https://img.reservoir.tools/images/v2/mainnet/i9YO%2F4yHXUdJsWcTqhqvfyI35XYV1Zv5cZk8AoWo3731IAT3hNWEvHJYjgU9nRc0tidKyp4c1s%2BpoonACataX8B7SeUf6fWUYoX0229DEeg%3D',
        'https://img.reservoir.tools/images/v2/mainnet/i9YO%2F4yHXUdJsWcTqhqvfyI35XYV1Zv5cZk8AoWo3731IAT3hNWEvHJYjgU9nRc0tidKyp4c1s%2BpoonACataXwzTtSFwF3%2FJbadAzOQo8R8%3D',
        'https://img.reservoir.tools/images/v2/mainnet/i9YO%2F4yHXUdJsWcTqhqvfyI35XYV1Zv5cZk8AoWo3731IAT3hNWEvHJYjgU9nRc0tidKyp4c1s%2BpoonACataX1FsNFmngVpPDGK7KS3mFIY%3D',
        'https://img.reservoir.tools/images/v2/mainnet/i9YO%2F4yHXUdJsWcTqhqvfyI35XYV1Zv5cZk8AoWo3731IAT3hNWEvHJYjgU9nRc0tidKyp4c1s%2BpoonACataX2XiLW4wxeWyXO%2BxT3wbJ8U%3D',
      ],
      externalUrl: 'http://valeriagames.com',
      twitter: ['https://twitter.com/ValeriaStudios'],
      discord: ['https://discord.gg/valeriagames'],
      imageUrl:
        'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuADDhRrCpOSg4%2Bw9m%2B9OEuqC0aUYQYNGxASbnJcHNhaSRhhXV6bwTJAcWYWcF9dWnjRh8KTUo2KPmdzg6Du%2FOoCROma9dQBYLiEKpOWUXtGnQG76H02kRn%2B23Nh7w3N3pnWsJo1oe7tZbSFTjIQIjfiB58hESavQz%2Bv2TERHH%2FKTc63AzkitO4b2%2B8lGsB1AqNA%3D%3D?width=250',
      flags: [{ key: 'reservoir_enabled', value: 'true', enabled: true }],
      marketplaceId: 'd5c445a9-a5ca-4e84-9866-e4be22873204',
    },
    {
      chainId: '1',
      collection: '0xdfed7b60c470e036449a661c5aa0cff00a55540e',
      slug: 'valeria-founders-set',
      symbol: 'VALBOX',
      imageBanner:
        'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAHKntCJqa3lFuGLn%2FFMZtX43UjtD2sDOq7HuGeQ5MKHe7y%2BwSMMdhjE7bs2sIrRa%2BmOuC%2BqZdzhmnPhJLAzQKgI%2F54OY9cHdqd3dJuqpE%2FtpmPjzpkGsbR2VIV0So1bVzV%2BTotRmYjK6g8HRWGcxO5Z%2B%2BnfBFGNpj%2FnTOHs1O0BINCL6xnDVdwus2z5Uh13ugg%3D%3D',
      shortDescription:
        "Visit https://market.valeriagames.com/ to trade all Valeria Games NFT's.",
      externalUrl:
        'https://market.valeriagames.com/collections/0xdfed7b60c470e036449a661c5aa0cff00a55540e/networks/mainnet',
      twitter: ['https://twitter.com/ValeriaStudios'],
      discord: ['https://discord.gg/valeriagames'],
      imageUrl:
        'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAI8ipNM7MJ1GQZfU57rB7lLMBwd%2FzdWPlswJ7U8pLRI4Sbsz5LRFfKQp%2BN57v8xoOhqumqZbbFZAaqNKY14dv6b%2BRAKlcz%2FvGwiR5G81DMaQ6RMgR0qQdWi%2Ff5e7WgEXjcT55yK0NOBdFRr16dL5Q48EcAnqXlFPBhC52%2F5kjy%2Byqy2hc4xce1p5VqfcccOCjQ%3D%3D?width=250',
      flags: [{ key: 'reservoir_enabled', value: 'true', enabled: true }],
      marketplaceId: 'd5c445a9-a5ca-4e84-9866-e4be22873204',
    },
    {
      chainId: '1',
      collection: '0xee5ef1216cacd7bb30ae9626f221476f4f943947',
      slug: 'valeria-champions',
      symbol: 'VC',
      imageBanner:
        'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAABOXXV%2Bcb5zs9SwjwL4bNWYH6l2%2BqLZ2q81Sin0zLxaEcR67xI3t3%2BB7MD9THlSniNvCjMsMo68ADA7Sk%2FPfRyQMfl1gpXwT2drPJ4iaCpr',
      shortDescription:
        "Visit https://market.valeriagames.com/ to trade all Valeria Games NFT's.",
      additionalImages: [
        'https://img.reservoir.tools/images/v2/mainnet/i9YO%2F4yHXUdJsWcTqhqvf3M9ot9ttFsYdKw6cV%2B93xoQUXnl9lxGCPe8W63vcPfvNlbcux7FS5E4cBEQc16e1L%2Ft1hCPGz8FOJK9gts4f40%3D',
        'https://img.reservoir.tools/images/v2/mainnet/i9YO%2F4yHXUdJsWcTqhqvf3M9ot9ttFsYdKw6cV%2B93xoQUXnl9lxGCPe8W63vcPfvNlbcux7FS5E4cBEQc16e1Pa22tgtEMDRdnQnASqt5pY%3D',
        'https://img.reservoir.tools/images/v2/mainnet/i9YO%2F4yHXUdJsWcTqhqvf3M9ot9ttFsYdKw6cV%2B93xoQUXnl9lxGCPe8W63vcPfvNlbcux7FS5E4cBEQc16e1PbkKt59v7p9aNgwjaNUhgw%3D',
        'https://img.reservoir.tools/images/v2/mainnet/i9YO%2F4yHXUdJsWcTqhqvf3M9ot9ttFsYdKw6cV%2B93xoQUXnl9lxGCPe8W63vcPfvNlbcux7FS5E4cBEQc16e1Ep6t44x95lkdmvk9%2BPVa4U%3D',
      ],
      externalUrl: 'http://valeriagames.com',
      twitter: ['https://twitter.com/ValeriaStudios'],
      discord: ['https://discord.gg/valeriagames'],
      imageUrl:
        'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAF8BWDBzrv7nEYhhMXmZgbpl8VsAspMRIdVTSOcYZ2AIhp%2B1YbgdqCcbJyH8V6nusV4UcoSe0f5h%2Byna%2BvZR23MJxPNoDYuk%2Fwd%2BjpzH5oH%2FHecS4%2Bwy4Mic7UHRBbrM9Lqt68%2FZoLWMQS%2BCOlMaRxQhAlxzT3EkQhqkMjLdIwp3F5qI7Wvs5T27XAS%2BF2GJKQ%3D%3D?width=250',
      flags: [{ key: 'reservoir_enabled', value: 'true', enabled: true }],
      marketplaceId: 'd5c445a9-a5ca-4e84-9866-e4be22873204',
    },
  ],
  themeOverrides: {},
  imageBanner:
    'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAI%2BnzPkECBTuIaOBSBU62KP26Y%2BBMfYJa%2BTNYAKZfzcGKM3nfdD4o7QSSdNiAsAP%2FuQxrBP5oL9M3DeexDQrGiqTNoSttZrmsPi68sIO3cwd',
  imageLogo:
    'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuADDhRrCpOSg4%2Bw9m%2B9OEuqC0aUYQYNGxASbnJcHNhaSRhhXV6bwTJAcWYWcF9dWnjRh8KTUo2KPmdzg6Du%2FOoCROma9dQBYLiEKpOWUXtGnQG76H02kRn%2B23Nh7w3N3pnWsJo1oe7tZbSFTjIQIjfiB58hESavQz%2Bv2TERHH%2FKTc63AzkitO4b2%2B8lGsB1AqNA%3D%3D?width=250',
  name: 'Valeria Games',
  description:
    "Visit https://market.valeriagames.com/ to trade all Valeria Games NFT's.",
  favicon: 'string',
  meta: {
    title: 'Valeria Games',
    description:
      "Visit https://market.valeriagames.com/ to trade all Valeria Games NFT's.",
    domain: 'valeria.staging.freenft.com',
    image:
      'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAI%2BnzPkECBTuIaOBSBU62KP26Y%2BBMfYJa%2BTNYAKZfzcGKM3nfdD4o7QSSdNiAsAP%2FuQxrBP5oL9M3DeexDQrGiqTNoSttZrmsPi68sIO3cwd',
  },
  socialLinks: {
    twitter: 'https://twitter.com/ValeriaStudios',
    discord: 'https://discord.gg/valeriagames',
    website: 'http://valeriagames.com',
  },
  hostnames: ['valeria.staging.freenft.com'],
  footer: {
    imageUrl:
      'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAABOXXV%2Bcb5zs9SwjwL4bNWYH6l2%2BqLZ2q81Sin0zLxaEcR67xI3t3%2BB7MD9THlSniNvCjMsMo68ADA7Sk%2FPfRyQMfl1gpXwT2drPJ4iaCpr',
  },
  resourceLinks: {
    tos: undefined,
    pp: undefined
  },
  mode: Mode.VALERIA,
};
