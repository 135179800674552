import { Currency } from '../orderbook_api/v1/types.pb';
import { zeroAddress } from 'viem';
import {
  arbitrum,
  arbitrumNova,
  avalanche,
  base,
  bsc,
  Chain,
  linea,
  mainnet,
  optimism,
  polygon,
  polygonZkEvm,
  scroll,
  sepolia,
  zkSync,
  zora,
} from 'wagmi/chains';
import usdcContracts from '@api/utils/usdcContracts';

//CONFIGURABLE: The default export controls the supported chains for the marketplace. Removing
// or adding chains will result in adding more or less chains to the marketplace.
// They are an extension of the wagmi chain objects

export type LimitBreakChain = Chain & {
  blockExplorerName?: string;
  lightIconUrl: string;
  darkIconUrl: string;
  routePrefix?: string;
  coingeckoId?: string;
  community?: string;
  listingCurrencies?: Currency[];
};

export const nativeCurrencyBase = {
  address: zeroAddress,
  symbol: 'ETH',
  decimals: 18,
  coinGeckoId: 'ethereum',
};

const usdcCurrencyBase = {
  address: '',
  symbol: 'USDC',
  decimals: 6,
  coinGeckoId: 'usd-coin',
};

export const SepoliaChain: LimitBreakChain = {
  ...sepolia,
  // Any url to display the logo of the chain in light mode
  lightIconUrl: '/icons/eth-icon-dark.svg',
  // Any url to display the logo of the chain in dark mode
  darkIconUrl: '/icons/eth-icon-light.svg',
  routePrefix: 'sepolia',
  coingeckoId: 'sepolia',
  community: process.env.NEXT_PUBLIC_ETH_COMMUNITY,
  listingCurrencies: [
    nativeCurrencyBase,
    {
      ...usdcCurrencyBase,
      address: usdcContracts[sepolia.id],
    },
  ],
};

export const DefaultChain: LimitBreakChain = {
  ...mainnet,
  blockExplorerName: 'Etherscan',
  // Any url to display the logo of the chain in light mode
  lightIconUrl: '/icons/eth-icon-dark.svg',
  // Any url to display the logo of the chain in dark mode
  darkIconUrl: '/icons/eth-icon-light.svg',
  routePrefix: 'ethereum',
  coingeckoId: 'ethereum',
  community: process.env.NEXT_PUBLIC_ETH_COMMUNITY,
  listingCurrencies: [
    nativeCurrencyBase,
    {
      ...usdcCurrencyBase,
      address: usdcContracts[mainnet.id],
    },
  ],
};

export default [
  DefaultChain,
  SepoliaChain,
  {
    ...polygon,
    blockExplorerName: 'Polygonscan',
    lightIconUrl: '/icons/polygon-icon-dark.svg',
    darkIconUrl: '/icons/polygon-icon-light.svg',
    routePrefix: 'polygon',
    coingeckoId: 'matic-network',
    community: process.env.NEXT_PUBLIC_POLYGON_COMMUNITY,
    listingCurrencies: [
      {
        ...nativeCurrencyBase,
        symbol: 'POL',
        coinGeckoId: 'matic-network',
      },
      {
        ...usdcCurrencyBase,
        address: usdcContracts[polygon.id],
      },
      {
        address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
        symbol: 'WETH',
        decimals: 18,
        coinGeckoId: 'weth',
      },
    ],
  },
  {
    ...arbitrum,
    name: 'Arbitrum',
    lightIconUrl: '/icons/arbitrum-icon-dark.svg',
    darkIconUrl: '/icons/arbitrum-icon-light.svg',
    coingeckoId: 'arbitrum-iou',
    community: process.env.NEXT_PUBLIC_ARBITRUM_COMMUNITY,
    listingCurrencies: [
      { ...nativeCurrencyBase, coinGeckoId: 'arbitrum-iou' },
      {
        ...usdcCurrencyBase,
        address: usdcContracts[arbitrum.id],
      },
    ],
  },
  {
    ...arbitrumNova,
    lightIconUrl: '/icons/arbitrum-nova-icon-dark.svg',
    darkIconUrl: '/icons/arbitrum-nova-icon-light.svg',
    coingeckoId: 'ethereum',
    community: process.env.NEXT_PUBLIC_ARBITRUM_NOVA_COMMUNITY,
  },
  {
    ...optimism,
    name: 'Optimism',
    lightIconUrl: '/icons/optimism-icon-dark.svg',
    darkIconUrl: '/icons/optimism-icon-light.svg',
    coingeckoId: 'optimism',
    community: process.env.NEXT_PUBLIC_OPTIMISM_COMMUNITY,
    listingCurrencies: [
      { ...nativeCurrencyBase, coinGeckoId: 'optimism' },
      {
        ...usdcCurrencyBase,
        address: usdcContracts[optimism.id],
      },
    ],
  },
  {
    ...zora,
    name: 'Zora',
    lightIconUrl: '/icons/zora-icon-dark.svg',
    darkIconUrl: '/icons/zora-icon-light.svg',
    coingeckoId: 'ethereum',
  },
  {
    ...bsc,
    lightIconUrl: '/icons/bsc-icon-dark.svg',
    darkIconUrl: '/icons/bsc-icon-light.svg',
    coingeckoId: 'binancecoin',
    community: process.env.NEXT_PUBLIC_BSC_COMMUNITY,
    listingCurrencies: [
      { ...nativeCurrencyBase, coinGeckoId: 'binancecoin' },
      {
        ...usdcCurrencyBase,
        address: usdcContracts[bsc.id],
      },
    ],
  },
  {
    ...avalanche,
    lightIconUrl: '/icons/avalanche-icon-dark.svg',
    darkIconUrl: '/icons/avalanche-icon-light.svg',
    coingeckoId: 'avalanche-2',
    community: process.env.NEXT_PUBLIC_AVALANCHE_COMMUNITY,
  },
  {
    ...base,
    lightIconUrl: '/icons/base-icon-dark.svg',
    darkIconUrl: '/icons/base-icon-light.svg',
    coingeckoId: 'ethereum',
    community: process.env.NEXT_PUBLIC_BASE_COMMUNITY,
  },
  {
    ...linea,
    lightIconUrl: '/icons/linea-icon-dark.svg',
    darkIconUrl: '/icons/linea-icon-light.svg',
    coingeckoId: 'ethereum',
    community: process.env.NEXT_PUBLIC_LINEA_COMMUNITY,
  },
  {
    ...polygonZkEvm,
    lightIconUrl: '/icons/polygon-zkevm-icon-dark.svg',
    darkIconUrl: '/icons/polygon-zkevm-icon-light.svg',
    coingeckoId: 'ethereum',
    community: process.env.NEXT_PUBLIC_POLYGON_ZKEVM_COMMUNITY,
  },
  {
    ...zkSync,
    name: 'zkSync',
    lightIconUrl: '/icons/zksync-icon-dark.svg',
    darkIconUrl: '/icons/zksync-icon-light.svg',
    coingeckoId: 'ethereum',
    community: process.env.NEXT_PUBLIC_ZKSYNC_COMMUNITY,
  },
  {
    ...scroll,
    name: 'Scroll',
    lightIconUrl: '/icons/scroll-testnet-icon-dark.svg',
    darkIconUrl: '/icons/scroll-testnet-icon-light.svg',
    coingeckoId: 'ethereum',
    community: process.env.NEXT_PUBLIC_SCROLL_COMMUNITY,
  },
] as LimitBreakChain[];
