import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useBalance, useReadContracts } from 'wagmi';
import { ExpirationOption } from '../../types/ExpirationOption';
import defaultExpirationOptions from '../../lib/defaultExpirationOptions';
import { formatBN } from '../../lib/numbers';
import dayjs from 'dayjs';
import wrappedContractNames from '../../constants/wrappedContractNames';
import wrappedContracts from '../../constants/wrappedContracts';
import { Address, erc20Abi, formatUnits, parseUnits, zeroAddress } from 'viem';
import { useAccount, useSwitchChain } from 'wagmi';
import { getCollection } from '@signals/collection';
import useCollectionToken from '@hooks/useCollectionToken';
import { ContractType, TokenAttribute } from '@api/common/v1/common.pb';
import useCollectionAttributes from '@hooks/useCollectionAttributes';
import {
  Collection,
  Token,
  OrderKind,
  TokenAttributeValue,
  Currency,
  Orderbook,
} from '@api/orderbook_api/v1/types.pb';
import { EnhancedStep } from '@types';
import { useChainCurrency, useCoinConversion } from '@hooks';
import { CreateBidRequest } from '@api/orderbook_api/v1/orderbook.pb';
import paymentProcessorExchange from '@utils/paymentProcessorExchange';
import { int } from '@api/utils';
import { useWallet } from '@hooks/useWallet';
import { useWatchQueryKey } from '@hooks/useWatchQueryKey';
import { getChainById } from '@signals/chains';
import { trackEvent } from '@utils/analytics/events';
import { OrderProtocol } from '@api/payment_processor/v2/payment_processor.pb';

const expirationOptions = [
  ...defaultExpirationOptions,
  {
    text: 'Custom',
    value: 'custom',
    relativeTime: null,
    relativeTimeUnit: null,
  },
];

export type FetchBalanceResult = {
  decimals: number;
  formatted: string;
  symbol: string;
  value: bigint;
};

export enum BidStep {
  SetPrice,
  Offering,
  Complete,
  Unavailable,
}

type Exchange = NonNullable<typeof paymentProcessorExchange>;

type ChildrenProps = {
  token?: Token;
  collection?: Collection;
  attributes?: TokenAttribute[];
  bidAmountPerUnit: string;
  totalBidAmount: bigint;
  totalBidAmountUsd: number;
  bidData: BidRequestData | null;
  bidStep: BidStep;
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  hasEnoughNativeCurrency: boolean;
  hasEnoughWrappedCurrency: boolean;
  loading: boolean;
  traitBidSupported: boolean;
  collectionBidSupported: boolean;
  amountToWrap: string;
  usdPrice: number | null;
  balance?: FetchBalanceResult;
  wrappedBalance?: BigInt;
  wrappedCurrency?: Currency;
  wrappedContractName: string;
  wrappedContractAddress: string;
  canAutomaticallyConvert: boolean;
  transactionError?: Error | null;
  expirationOptions: ExpirationOption[];
  expirationOption: ExpirationOption;
  stepData: BidModalStepData | null;
  currency: Currency;
  exchange?: Exchange;
  setBidStep: React.Dispatch<React.SetStateAction<BidStep>>;
  setBidAmountPerUnit: React.Dispatch<React.SetStateAction<string>>;
  setExpirationOption: React.Dispatch<React.SetStateAction<ExpirationOption>>;
  setAttribute: React.Dispatch<
    React.SetStateAction<TokenAttributeValue | undefined>
  >;
  trait?: TokenAttributeValue;
  placeBid: () => void;
};

type Props = {
  tokenId?: string;
  collectionId?: string;
  attribute?: TokenAttributeValue;
  currencies?: Currency[];
  children: (props: ChildrenProps) => ReactNode;
};

export type BidRequestData = CreateBidRequest;

export type BidModalStepData = {
  totalSteps: number;
  stepProgress: number;
  currentStep: EnhancedStep;
};

export const BidModalRenderer: FC<Props> = ({
  tokenId,
  collectionId,
  attribute,
  currencies: preferredCurrencies,
  children,
}) => {
  const { wallet } = useWallet();
  const { address } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  let { chain: activeWalletChain } = useAccount();

  const [bidStep, setBidStep] = useState<BidStep>(BidStep.SetPrice);
  const [transactionError, setTransactionError] = useState<Error | null>();
  const [bidAmountPerUnit, setBidAmountPerUnit] = useState<string>('');
  const [expirationOption, setExpirationOption] = useState<ExpirationOption>(
    expirationOptions[3],
  );
  const [hasEnoughNativeCurrency, setHasEnoughNativeCurrency] = useState(false);
  const [hasEnoughWrappedCurrency, setHasEnoughWrappedCurrency] =
    useState(false);
  const [amountToWrap, setAmountToWrap] = useState('');
  const [stepData, setStepData] = useState<BidModalStepData | null>(null);
  const [bidData, setBidData] = useState<BidRequestData | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [attributeValue, setAttributeValue] = useState(attribute);
  const [attributes, setAttributes] = useState<TokenAttribute[]>();

  const collection = getCollection(collectionId);
  const rendererChain = getChainById(Number(collection?.chainId));
  const chainCurrency = useChainCurrency(rendererChain.id);
  const nativeWrappedContractAddress =
    chainCurrency.chainId in wrappedContracts
      ? wrappedContracts[int(chainCurrency.chainId)]
      : wrappedContracts[1];
  const nativeWrappedContractName =
    chainCurrency.chainId in wrappedContractNames
      ? wrappedContractNames[int(chainCurrency.chainId)]
      : wrappedContractNames[1];

  const defaultCurrency = useMemo(
    () => ({
      contract: nativeWrappedContractAddress,
      address: nativeWrappedContractAddress,
      symbol: nativeWrappedContractName,
    }),
    [nativeWrappedContractAddress, nativeWrappedContractName],
  );
  const [currency, setCurrency] = useState<Currency>(defaultCurrency);
  const wrappedContractAddress = currency.address
    ? currency.address
    : nativeWrappedContractAddress;
  const wrappedContractName = currency.symbol
    ? currency.symbol
    : nativeWrappedContractName;

  const { data: token, isLoading } = useCollectionToken({
    tokenId,
    collection: collectionId,
    chainId: rendererChain?.id.toString(),
  });

  const { data: traitsResponse } = useCollectionAttributes(
    collectionId,
    rendererChain?.id,
  );

  const traits = traitsResponse?.attributes;

  const usdConversion = useCoinConversion('USD', wrappedContractName);
  const usdPrice = usdConversion.length > 0 ? usdConversion[0].price : null;
  const totalBidAmount =
    parseUnits(bidAmountPerUnit, currency?.decimals ?? 18) *
    BigInt(quantity ?? 1);
  const totalBidAmountUsd =
    Number(formatUnits(totalBidAmount, currency?.decimals ?? 18)) *
    (usdPrice || 0);

  const traitBidSupported = true;
  const collectionBidSupported = true;

  // Set bid step to unavailable if collection bid is not supported
  useEffect(() => {
    if (!tokenId && !collectionBidSupported) {
      setBidStep(BidStep.Unavailable);
    } else {
      setBidStep(BidStep.SetPrice);
    }
  }, [tokenId, collectionBidSupported]);

  const { data: balance, queryKey: queryKeyBalance } = useBalance({
    address,
    chainId: rendererChain?.id,
    query: {
      enabled: !!address,
    },
  });

  const { data: wrappedBalance, queryKey: queryKeyWrappedBalance } =
    useReadContracts({
      allowFailure: false,
      contracts: [
        {
          address: wrappedContractAddress as Address,
          abi: erc20Abi,
          functionName: 'balanceOf',
          chainId: rendererChain?.id,
          args: [address as Address],
        },
      ],
      query: {
        enabled: address && wrappedContractAddress !== zeroAddress,
      },
    });

  useWatchQueryKey({ queryKey: queryKeyBalance });
  useWatchQueryKey({ queryKey: queryKeyWrappedBalance });

  const wrappedBalanceValue = wrappedBalance?.[0] ?? BigInt(0);

  const canAutomaticallyConvert =
    !currency ||
    currency.address?.toLowerCase() ===
      nativeWrappedContractAddress.toLowerCase();

  useEffect(() => {
    if (totalBidAmount !== 0n) {
      const bid = totalBidAmount;
      if (wrappedBalanceValue < bid) {
        setHasEnoughWrappedCurrency(false);
        const amountToWrap = bid - wrappedBalanceValue;
        setAmountToWrap(formatBN(amountToWrap, 5));

        if (!balance?.value || balance.value < amountToWrap) {
          setHasEnoughNativeCurrency(false);
        } else {
          setHasEnoughNativeCurrency(true);
        }
      } else {
        setHasEnoughWrappedCurrency(true);
        setHasEnoughNativeCurrency(true);
        setAmountToWrap('');
      }
    } else {
      setHasEnoughNativeCurrency(true);
      setHasEnoughWrappedCurrency(true);
      setAmountToWrap('');
    }
  }, [totalBidAmount, balance, wrappedBalance]);

  useEffect(() => {
    const validAttributes = traits
      ? traits.filter(
          (attribute) => attribute.values && attribute.values.length > 0,
        )
      : undefined;
    setAttributes(validAttributes);
  }, [traits]);

  useEffect(() => {
    const validAttributes = traits
      ? traits.filter(
          (attribute) => attribute.values && attribute.values.length > 0,
        )
      : undefined;
    setAttributes(validAttributes);
  }, [traits]);

  useEffect(() => {
    if (!open) {
      setBidStep(BidStep.SetPrice);
      setExpirationOption(expirationOptions[3]);
      setHasEnoughNativeCurrency(false);
      setHasEnoughWrappedCurrency(false);
      setAmountToWrap('');
      setQuantity(1);
      setBidAmountPerUnit('');
      setStepData(null);
      setBidData(null);
      setTransactionError(null);
      setAttributeValue(undefined);
    } else {
      setAttributeValue(attribute);
    }
    setCurrency(defaultCurrency);
  }, [attribute, defaultCurrency, open]);

  const exchange = paymentProcessorExchange;
  useEffect(() => {
    if (exchange?.paymentTokens) {
      const restrictedCurrencies = exchange.paymentTokens.filter(
        (token) =>
          token.address &&
          token.symbol &&
          exchange.supportedBidCurrencies.includes(token.address.toLowerCase()),
      );

      if (
        !restrictedCurrencies.find(
          (c) => currency.address?.toLowerCase() == c.address?.toLowerCase(),
        )
      ) {
        // currency is not supported, reset to default
        setCurrency(defaultCurrency);
      }
    } else {
      setCurrency(defaultCurrency);
    }
  }, [currency.address, defaultCurrency, exchange]);

  const placeBid = useCallback(async () => {
    trackEvent('offer_modal_make_offer_clicked');
    if (!wallet) {
      const error = new Error('Missing a wallet/signer');
      setTransactionError(error);
      throw error;
    }

    if (activeWalletChain && rendererChain?.id !== activeWalletChain?.id) {
      activeWalletChain = await switchChainAsync({
        chainId: rendererChain?.id as number,
      });
    }

    if (rendererChain?.id !== activeWalletChain?.id) {
      const error = new Error(`Mismatching chainIds`);
      setTransactionError(error);
      throw error;
    }

    if (!tokenId && !collectionId) {
      const error = new Error('Missing tokenId and collectionId');
      setTransactionError(error);
      throw error;
    }

    if (!tokenId && !collectionBidSupported) {
      const error = new Error(
        'Collection bids are not supported for this collection',
      );
      setTransactionError(error);
      throw error;
    }

    setBidStep(BidStep.Offering);
    setTransactionError(null);
    setBidData(null);

    const bidRequest: BidRequestData = {
      collection: collectionId,
      tokenId,
      amount: quantity.toString() || '1',
      itemPrice: totalBidAmount.toString(),
      chainId: rendererChain.id.toString(),
      orderbook: Orderbook.LIMIT_BREAK,
      kind: OrderKind.PAYMENT_PROCESSOR_V2,
      attributes: attributeValue?.key
        ? [
            {
              key: attributeValue?.key,
              values: [attributeValue?.value as string],
            },
          ]
        : [],
      protocol:
        token?.isErc1155 ||
        collection?.type === ContractType.CONTRACT_TYPE_ERC1155 ||
        collection?.type === ContractType.CONTRACT_TYPE_ERC1155C
          ? OrderProtocol.ERC1155_FILL_OR_KILL
          : OrderProtocol.ERC721_FILL_OR_KILL,
    };
    if (currency) {
      bidRequest.paymentMethod = currency.address;
    }
    bidRequest.tokenId = tokenId;
    bidRequest.collection = collectionId;

    if (expirationOption.relativeTime) {
      if (expirationOption.relativeTimeUnit) {
        bidRequest.expiration = dayjs()
          .add(expirationOption.relativeTime, expirationOption.relativeTimeUnit)
          .unix()
          .toString();
      } else {
        bidRequest.expiration = `${expirationOption.relativeTime}`;
      }
    }

    setBidData(bidRequest);

    wallet
      .createBid({
        request: bidRequest,
        beforeStep: async (steps, step) => {
          const executableSteps = steps.steps?.filter(
            (step) => step.items && step.items.length > 0,
          );

          let stepCount = executableSteps?.length || 0;

          setStepData({
            totalSteps: stepCount,
            stepProgress: 0,
            currentStep: {
              ...step,
              description: '',
            } as EnhancedStep,
          });
        },
      })
      .then((result) => {
        trackEvent('offer_modal_transaction_succeeded');
        setBidStep(BidStep.Complete);
      })
      .catch((e: Error) => {
        trackEvent('offer_modal_transaction_failed');
        setTransactionError(e);
      });
  }, [
    wallet,
    rendererChain.id,
    tokenId,
    collectionId,
    collectionBidSupported,
    quantity,
    totalBidAmount,
    currency,
    attributeValue?.key,
    attributeValue?.value,
    expirationOption.relativeTime,
    expirationOption.relativeTimeUnit,
  ]);

  return (
    <>
      {children({
        token: token as Token,
        collection,
        attributes,
        usdPrice,
        balance,
        wrappedBalance: wrappedBalanceValue,
        wrappedCurrency: currency,
        wrappedContractName,
        wrappedContractAddress,
        canAutomaticallyConvert,
        bidAmountPerUnit,
        totalBidAmount,
        bidData,
        quantity,
        setQuantity,
        totalBidAmountUsd,
        bidStep,
        loading: !collection || isLoading,
        hasEnoughNativeCurrency,
        hasEnoughWrappedCurrency,
        traitBidSupported,
        collectionBidSupported,
        amountToWrap,
        transactionError,
        expirationOption,
        expirationOptions,
        stepData,
        currency,
        exchange,
        setBidStep,
        setBidAmountPerUnit,
        setExpirationOption,
        setAttribute: setAttributeValue,
        trait: attributeValue,
        placeBid,
      })}
    </>
  );
};
