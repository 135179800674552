/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  ComponentPropsWithoutRef,
  useMemo,
} from 'react';
import {
  Flex,
  Text,
  FormatWrappedCurrency,
  Box,
  Input,
  Select,
  Button,
  ErrorWell,
  Loader,
  FormatCryptoCurrency,
  CryptoCurrencyIcon,
  Img,
  DateDisplay,
} from '../../primitives';
import { Modal } from '../Modal';
import {
  BidModalRenderer,
  BidStep,
  BidRequestData,
  BidModalStepData,
} from './BidModalRenderer';

import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand } from '@fortawesome/free-solid-svg-icons';
import Flatpickr from 'react-flatpickr';
import AttributeSelector from './AttributeSelector';
import Popover from '../../primitives/Popover';
import { formatNumber } from '../../lib/numbers';
import { Dialog } from '../../primitives/Dialog';
import { getTimeSince } from '@reservoir-kit-ui-overrides/lib/getTimeSince';
import {
  Currency,
  StepKind,
  TokenAttributeValue,
} from '@api/orderbook_api/v1/types.pb';
import { useMarketplaceChain } from '@hooks';
import { TokenInfo } from '@reservoir-kit-ui-overrides/common';
import { PriceTotal } from '@reservoir-kit-ui-overrides/common/PriceTotal';
import { PriceInfo } from './PriceInfo';
import { CollectionInfo } from '../CollectionInfo';
import { useMediaQuery } from 'react-responsive';
import { TokenIconWithPriceListing } from '@reservoir-kit-ui-overrides/components/TokenIconWithPriceListing/TokenIconWithPriceListing';
import { zeroAddress } from '@api/utils';
import { getCollection } from '@signals/collection';
import { getTopOffer } from '@utils/getTopOffer';
import { useWallet } from '@hooks/useWallet';
import { setModalState } from '@signals/modalState';
import QuantitySelector from '../QuantitySelector';
import { useOpenState } from '@hooks/useOpenState';
import { trackEvent } from '@utils/analytics/events';
import { Tag } from '@v2/components/Filters/Tag/Tag';
import { parseUnits } from 'viem';

type BidCallbackData = {
  tokenId?: string;
  collectionId?: string;
  bidData: BidRequestData | null;
};

const ModalCopy = {
  titleSetPrice: 'Make an Offer',
  titleSetPriceCollection: 'Make collection offer',
  titleConfirm: 'Complete Offer',
  titleComplete: 'Your offer is submitted!',
  ctaBidDisabled: 'Make offer',
  ctaBid: '',
  ctaConvertManually: 'Convert Manually',
  ctaConvertAutomatically: '',
  ctaAwaitingApproval: 'Waiting for Approval',
  ctaEditOffer: 'Edit Offer',
  ctaRetry: 'Retry',
  ctaViewOffers: 'View Offers',
  ctaClose: 'Close',
  ctaButtonText: 'Make offer',
};

type Props = Pick<Parameters<typeof Modal>['0'], 'trigger'> & {
  tokenId?: string;
  collectionId?: string;
  attribute?: TokenAttributeValue;
  currencies?: Currency[];
  copyOverrides?: Partial<typeof ModalCopy>;
  onViewOffers?: () => void;
  onClose?: (
    data: BidCallbackData,
    stepData: BidModalStepData | null,
    currentStep: BidStep,
  ) => void;
  onBidComplete?: (data: any) => void;
  onBidError?: (error: Error, data: any) => void;
  onPointerDownOutside?: ComponentPropsWithoutRef<
    typeof Dialog
  >['onPointerDownOutside'];
};

function titleForStep(step: BidStep, copy: typeof ModalCopy, tokenId?: string) {
  switch (step) {
    case BidStep.SetPrice:
    case BidStep.Unavailable:
      return tokenId ? copy.titleSetPrice : copy.titleSetPriceCollection;
    case BidStep.Offering:
      return copy.titleConfirm;
    case BidStep.Complete:
      return copy.titleComplete;
  }
}

const MINIMUM_DATE = dayjs().add(1, 'h').format('MM/DD/YYYY h:mm A');
const MINIMUM_AMOUNT = 0.000001;
const MAXIMUM_AMOUNT = Infinity;

export function BidModal({
  trigger,
  tokenId,
  collectionId,
  attribute,
  currencies,
  copyOverrides,
  onViewOffers,
  onClose,
  onBidComplete,
  onBidError,
  onPointerDownOutside,
}: Props): ReactElement {
  const copy: typeof ModalCopy = { ...ModalCopy, ...copyOverrides };
  const { isOpen, open, setIsOpen, createCloseHandler } = useOpenState({
    onChange: setModalState,
  });

  const modalChain = useMarketplaceChain();
  const isSmallDevice = useMediaQuery({ maxWidth: 600 });

  const datetimeElement = useRef<Flatpickr | null>(null);
  const [stepTitle, setStepTitle] = useState('');
  const [attributesSelectable, setAttributesSelectable] = useState(false);

  const [attributeSelectorOpen, setAttributeSelectorOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      trackEvent('offer_modal_opened');
    }
  }, [isOpen]);

  if (!isOpen) {
    return <div onClick={open}>{trigger}</div>;
  }

  return (
    <BidModalRenderer
      tokenId={tokenId}
      collectionId={collectionId}
      attribute={attribute}
      currencies={currencies}
    >
      {({
        token,
        collection,
        attributes,
        bidStep,
        expirationOption,
        expirationOptions,
        wrappedBalance,
        wrappedContractName,
        wrappedContractAddress,
        bidAmountPerUnit,
        quantity,
        setQuantity,
        totalBidAmount,
        totalBidAmountUsd,
        hasEnoughNativeCurrency,
        hasEnoughWrappedCurrency,
        loading,
        traitBidSupported,
        collectionBidSupported,
        amountToWrap,
        balance,
        canAutomaticallyConvert,
        transactionError,
        stepData,
        bidData,
        currency,
        exchange,
        setBidAmountPerUnit,
        setExpirationOption,
        setBidStep,
        setAttribute,
        trait,
        placeBid,
      }) => {
        const [expirationDate, setExpirationDate] = useState('');
        const tokenCollection = getCollection(token?.tokenId);
        const tokenCount = collection?.totalSupply
          ? +collection.totalSupply
          : undefined;
        const { wallet } = useWallet();
        const is1155 = token?.isErc1155;

        const close = createCloseHandler(() => {
          trackEvent('offer_modal_closed');
          onClose?.(
            {
              tokenId: tokenId,
              collectionId: collectionId,
              bidData,
            },
            stepData,
            bidStep,
          );
        });

        const quantityEnabled =
          !tokenId ||
          (is1155 && Number(token?.tokenOwnerErc1155?.totalSupply) > 1);

        const expirationDisplay =
          expirationOption?.value === 'custom' && expirationOption.relativeTime
            ? getTimeSince(expirationOption.relativeTime)
            : expirationOption?.text.toLowerCase();

        useEffect(() => {
          if (stepData) {
            switch (stepData.currentStep.kind) {
              case StepKind.SIGNATURE: {
                setStepTitle('Confirm Offer');
                break;
              }
              default: {
                setStepTitle(stepData.currentStep.action?.toString() || '');
                break;
              }
            }
          }
        }, [stepData]);

        useEffect(() => {
          if (expirationOption && expirationOption.relativeTime) {
            const newExpirationTime = expirationOption.relativeTimeUnit
              ? dayjs().add(
                  expirationOption.relativeTime,
                  expirationOption.relativeTimeUnit,
                )
              : dayjs.unix(expirationOption.relativeTime);
            setExpirationDate(newExpirationTime.format('MM/DD/YYYY h:mm A'));
          } else {
            setExpirationDate('');
          }
        }, [expirationOption]);

        useEffect(() => {
          if (bidStep === BidStep.Complete && onBidComplete) {
            const data: BidCallbackData = {
              tokenId: tokenId,
              collectionId: collectionId,
              bidData,
            };
            onBidComplete(data);
          }
        }, [bidData, bidStep]);

        useEffect(() => {
          if (transactionError && onBidError) {
            const data: BidCallbackData = {
              tokenId: tokenId,
              collectionId: collectionId,
              bidData,
            };
            onBidError(transactionError, data);
          }
        }, [bidData, transactionError]);

        useEffect(() => {
          if (attributes && !tokenId && attribute) {
            setAttribute(attribute);
          } else {
            setAttribute(undefined);
          }

          if (attributes && !tokenId) {
            let attributeCount = 0;
            for (let i = 0; i < attributes.length; i++) {
              attributeCount += attributes[i].values?.length || 0;
              if (attributeCount >= 2000) {
                break;
              }
            }
            if (attributeCount >= 2000) {
              setAttributesSelectable(false);
            } else {
              setAttributesSelectable(true);
            }
          } else {
            setAttributesSelectable(false);
          }
        }, [attributes, setAttribute]);

        const minimumAmount = MINIMUM_AMOUNT;
        const minimumAmountRaw = parseUnits(
          `${minimumAmount}`,
          currency?.decimals ?? 18,
        );
        const maximumAmount = MAXIMUM_AMOUNT;
        const maximumAmountRaw =
          maximumAmount !== Infinity
            ? parseUnits(`${maximumAmount}`, currency?.decimals ?? 18)
            : null;

        const withinPricingBounds =
          totalBidAmount !== 0n &&
          (maximumAmountRaw ? totalBidAmount <= maximumAmountRaw : true) &&
          totalBidAmount >= minimumAmountRaw;

        const canPurchase = bidAmountPerUnit !== '' && withinPricingBounds;

        const topBidPrice = useMemo(() => {
          return getTopOffer({
            token,
            collection,
            address: wallet?.address,
          })?.price;
        }, [token, collection, wallet]);

        const decimalTopBidPrice = topBidPrice?.amount?.decimal;
        const nativeTopBidPrice = topBidPrice?.amount?.native;
        const usdTopBidPrice = topBidPrice?.amount?.usd;
        const defaultCurrency = currencies?.find(
          (currency) => currency?.address === zeroAddress,
        );

        const topOfferButtonEnabled =
          (currency?.address?.toLowerCase() ===
            topBidPrice?.currency?.address?.toLowerCase() &&
            decimalTopBidPrice) ||
          (currency.symbol === 'USDC' && usdTopBidPrice) ||
          (nativeTopBidPrice && currency.address === zeroAddress) ||
          (nativeTopBidPrice && defaultCurrency);

        const handleSetBestOffer = () => {
          trackEvent('offer_modal_set_to_best_offer_clicked');
          // If currency matches top bid currency, use decimal floor price
          if (
            currency.address?.toLowerCase() ===
              topBidPrice?.currency?.address?.toLowerCase() &&
            decimalTopBidPrice
          ) {
            setBidAmountPerUnit(decimalTopBidPrice.toString());
          }

          // If currency is USDC, use usd floor price
          else if (currency.symbol === 'USDC' && usdTopBidPrice) {
            setBidAmountPerUnit(usdTopBidPrice?.toString());
          } else if (nativeTopBidPrice) {
            // If currency is native currency, use native floor price
            if (currency.address === zeroAddress) {
              setBidAmountPerUnit(nativeTopBidPrice.toString());
            }
            // TODO: Make sure we don't need to support multiple currencies for bids
            // Fallback to default currency if it exists
            // else {
            //   if (defaultCurrency) {
            //     setCurrency(defaultCurrency);
            //     setBidAmountPerUnit(nativeTopBidPrice.toString());
            //   }
            // }
          }
        };

        // bidStep = BidStep.SetPrice;
        // bidStep = BidStep.Offering
        // bidStep = BidStep.Complete;

        const tokenInfo = () => {
          if (loading) {
            return <Loader />;
          }
          if (isSmallDevice) {
            return (
              <TokenIconWithPriceListing
                chainId={modalChain.id}
                img={
                  token?.imageUrlSmall ||
                  token?.imageUrl ||
                  tokenCollection?.config?.imageUrl
                }
                name={token?.name}
                price={{
                  amount: { raw: totalBidAmount.toString() },
                  currency: {
                    address: currency?.address as string,
                    symbol: currency?.symbol as string,
                  },
                }}
                totalUsd={totalBidAmountUsd}
              />
            );
          }
          if (token) {
            return (
              <TokenInfo
                chain={modalChain}
                token={token ? token : undefined}
                collection={collection}
              />
            );
          }
          return (
            <CollectionInfo
              collection={collection}
              subtextSlot={
                <Text color='subtle'>
                  You are making an offer for any item in the collection
                </Text>
              }
            />
          );
        };

        return (
          <Modal
            trigger={trigger}
            title={titleForStep(bidStep, copy, tokenId)}
            open={true}
            onOpenChange={setIsOpen}
            loading={loading}
            onPointerDownOutside={(e) => {
              if (
                e.target instanceof Element &&
                datetimeElement.current?.flatpickr?.calendarContainer &&
                datetimeElement.current.flatpickr.calendarContainer.contains(
                  e.target,
                )
              ) {
                e.preventDefault();
              }
              if (onPointerDownOutside) {
                onPointerDownOutside(e);
              }
            }}
            onFocusCapture={(e) => {
              e.stopPropagation();
            }}
          >
            {bidStep === BidStep.SetPrice && !loading && collection && (
              <Flex direction='column'>
                <Flex
                  justify='between'
                  direction='column'
                  align='center'
                  css={{
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Flex
                    direction='column'
                    css={{
                      width: '100%',
                      p: '$modalContentPadding',
                    }}
                  >
                    <Text style='subtitle1'>Offer Price</Text>
                    {/* <Text
                        as={Flex}
                        css={{ gap: '$1' }}
                        align='center'
                        style='subtitle3'
                      >
                        Balance:{' '}
                        <FormatWrappedCurrency
                          chainId={modalChain?.id}
                          logoWidth={10}
                          textStyle='tiny'
                          amount={wrappedBalance?.value}
                          address={wrappedContractAddress}
                          decimals={wrappedBalance?.decimals}
                          symbol={wrappedBalance?.symbol}
                        />{' '}
                      </Text> */}

                    <PriceInfo token={token} collection={collection} />

                    <Flex css={{ mt: '$3', gap: '$2' }}>
                      <Input
                        type='number'
                        step='any'
                        value={bidAmountPerUnit}
                        onChange={(e) => {
                          setBidAmountPerUnit(e.target.value);
                        }}
                        placeholder='Price'
                        containerCss={{
                          width: '100%',
                        }}
                        css={{
                          // textAlign: 'center',
                          '@bp1': {
                            textAlign: 'left',
                          },
                        }}
                        rightSlot={
                          <Text
                            style='subtitle1'
                            color='subtle'
                            css={{ fontSize: 15, fontWeight: 600 }}
                          >
                            {wrappedContractName}
                          </Text>
                        }
                      />
                      {topOfferButtonEnabled && (
                        <Button
                          color='secondary'
                          size='none'
                          css={{
                            px: '$4',
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                          onClick={handleSetBestOffer}
                        >
                          Set to best offer
                        </Button>
                      )}
                    </Flex>

                    {totalBidAmount !== 0n && !withinPricingBounds && (
                      <Box>
                        <Text style='body2' color='error'>
                          {maximumAmount !== Infinity
                            ? `Amount must be between ${formatNumber(
                                minimumAmount,
                                6,
                              )} - ${formatNumber(maximumAmount)}`
                            : `Amount must be higher than ${formatNumber(
                                minimumAmount,
                                6,
                              )}`}
                        </Text>
                      </Box>
                    )}

                    {attributes &&
                      attributes.length > 0 &&
                      (attributesSelectable || trait) &&
                      !tokenId &&
                      traitBidSupported && (
                        <Flex
                          css={{ flexDirection: 'column', pt: '$2', gap: '$2' }}
                        >
                          <Text style='subtitle1'>Selected Trait</Text>

                          <Popover.Root
                            open={attributeSelectorOpen}
                            onOpenChange={
                              attributesSelectable
                                ? setAttributeSelectorOpen
                                : undefined
                            }
                          >
                            <Popover.Trigger asChild>
                              {trait ? (
                                <div style={{ paddingBottom: '12px' }}>
                                  <Tag
                                    name={trait.key as string}
                                    value={trait.value as string}
                                    onRemove={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setAttribute(undefined);
                                    }}
                                  />
                                </div>
                              ) : (
                                <div style={{ width: 'min-content' }}>
                                  <Button
                                    color='ghost'
                                    css={{
                                      color: '$accentText',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      // maxWidth: 'max-content',
                                    }}
                                    size='none'
                                  >
                                    + Add Trait
                                  </Button>
                                </div>
                              )}
                            </Popover.Trigger>
                            <Popover.Content
                              side='bottom'
                              align='start'
                              sideOffset={-20}
                              style={{ maxWidth: '100vw' }}
                            >
                              <AttributeSelector
                                chainId={modalChain.id}
                                attributes={attributes}
                                tokenCount={tokenCount}
                                setAttributeValue={setAttribute}
                                setOpen={setAttributeSelectorOpen}
                              />
                            </Popover.Content>
                          </Popover.Root>
                        </Flex>
                      )}
                  </Flex>
                  {quantityEnabled && (
                    <Flex
                      justify='between'
                      align='start'
                      direction='column'
                      css={{
                        p: '$modalContentPadding',
                        pt: '$2',
                        gap: '$3',
                        width: '100%',
                      }}
                    >
                      <Flex
                        direction='column'
                        align='start'
                        css={{ flexShrink: 0 }}
                      >
                        <Text style='subtitle1'>Quantity</Text>
                        <Text color='subtle' style='body1'>
                          Offers can be accepted separately
                        </Text>
                      </Flex>
                      <QuantitySelector
                        quantity={quantity}
                        setQuantity={setQuantity}
                        min={1}
                        max={999999}
                        css={{ justifyContent: 'space-between', width: '100%' }}
                      />
                      {quantity > 1 && (
                        <Flex css={{ gap: '$2' }}>
                          <div>Total:</div>{' '}
                          <FormatCryptoCurrency
                            chainId={Number(collection.chainId)}
                            amount={Number(Number(bidAmountPerUnit) * quantity)}
                            address={currency?.address}
                            symbol={currency?.symbol}
                            textStyle='h6'
                          />
                        </Flex>
                      )}
                    </Flex>
                  )}

                  <Flex
                    direction='column'
                    css={{
                      gap: '$2',
                      width: '100%',
                      p: '$modalContentPadding',
                      pt: '$2',
                    }}
                  >
                    <Text as={Box} style='subtitle1'>
                      Duration
                    </Text>
                    <Flex
                      css={{
                        gap: '$3',
                        flexDirection: 'column',
                        '@sm': {
                          flexDirection: 'row',
                          gap: '$2',
                          justifyContent: 'space-between',
                        },
                      }}
                    >
                      <Select
                        css={{
                          flex: 1,
                          '@sm': {
                            maxWidth: 160,
                            width: 160,
                            flexDirection: 'row',
                          },
                        }}
                        value={expirationOption?.text || ''}
                        onValueChange={(value: string) => {
                          const option = expirationOptions.find(
                            (option) => option.value == value,
                          );
                          if (option) {
                            trackEvent('offer_modal_duration_set');
                            setExpirationOption(option);
                          }
                        }}
                      >
                        {expirationOptions
                          .filter(({ value }) => value !== 'custom')
                          .map((option) => (
                            <Select.Item key={option.text} value={option.value}>
                              <Select.ItemText>{option.text}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select>
                      <DateDisplay value={expirationDate} />
                    </Flex>
                  </Flex>

                  {/* <Flex
                    justify='between'
                    align='center'
                    css={{ gap: '$4', width: '100%' }}
                  >
                    <Text style='h6'>Total Offer Price</Text>
                    <Flex direction='column' align='end'>
                      <FormatWrappedCurrency
                        chainId={modalChain?.id}
                        logoWidth={16}
                        textStyle='h6'
                        amount={totalBidAmount}
                        address={currency?.address}
                        decimals={currency?.decimals}
                        symbol={currency?.symbol}
                      />
                      <FormatCurrency
                        style='subtitle3'
                        color='subtle'
                        amount={totalBidAmountUsd}
                      />
                    </Flex>
                  </Flex> */}

                  <Box
                    css={{
                      width: '100%',
                      p: '$modalContentPadding',
                      borderTop: '1px solid $borderColor',
                    }}
                  >
                    <Flex
                      justify='between'
                      align='center'
                      css={{ width: '100%' }}
                    >
                      {tokenInfo()}
                    </Flex>
                  </Box>

                  <Box
                    css={{
                      width: '100%',
                      p: '$modalContentPadding',
                      borderTop: '1px solid $borderColor',
                    }}
                  >
                    {!canPurchase && (
                      <Button disabled={true} css={{ width: '100%' }}>
                        {copy.ctaBidDisabled}
                      </Button>
                    )}
                    {canPurchase && hasEnoughWrappedCurrency && (
                      <Button
                        onClick={() => placeBid()}
                        css={{ width: '100%' }}
                      >
                        {copy.ctaButtonText}
                      </Button>
                    )}
                    {canPurchase && !hasEnoughWrappedCurrency && (
                      <>
                        <Flex
                          direction='column'
                          css={{
                            gap: '$2',
                            overflow: 'hidden',
                          }}
                        >
                          {!hasEnoughNativeCurrency && (
                            <Flex
                              css={{ gap: '$2' }}
                              justify='center'
                              align='center'
                            >
                              <Text style='body2' color='error'>
                                {balance?.symbol || 'ETH'} Balance
                              </Text>
                              <FormatCryptoCurrency
                                chainId={modalChain?.id}
                                amount={balance?.value}
                                symbol={balance?.symbol}
                                textStyle='body2'
                              />
                            </Flex>
                          )}
                          {canAutomaticallyConvert && (
                            <Button
                              css={{ width: '100%' }}
                              disabled={!hasEnoughNativeCurrency}
                              onClick={() => placeBid()}
                            >
                              {copy.ctaConvertAutomatically.length > 0
                                ? copy.ctaConvertAutomatically
                                : `Convert ${amountToWrap} ${
                                    balance?.symbol || 'ETH'
                                  } for me`}
                            </Button>
                          )}
                        </Flex>
                      </>
                    )}
                  </Box>
                </Flex>
              </Flex>
            )}

            {bidStep === BidStep.Offering && collection && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Flex
                  align='center'
                  justify='between'
                  css={{
                    padding: '$modalContentPadding',
                    borderBottom: '1px solid $borderColor',
                  }}
                >
                  {isSmallDevice ? (
                    <TokenIconWithPriceListing
                      chainId={modalChain.id}
                      img={
                        token?.imageUrlSmall ||
                        tokenCollection?.config?.imageUrl
                      }
                      name={token?.name}
                      price={{
                        amount: { raw: totalBidAmount.toString() },
                        currency: {
                          address: currency?.address as string,
                          symbol: currency?.symbol as string,
                        },
                      }}
                      totalUsd={totalBidAmountUsd}
                      expirationDisplay={expirationDisplay}
                    />
                  ) : (
                    <>
                      <TokenInfo
                        chain={modalChain}
                        token={token ? token : undefined}
                        collection={collection}
                      />
                      <PriceTotal
                        chain={modalChain}
                        currency={currency}
                        price={totalBidAmount}
                        usdPrice={totalBidAmountUsd}
                        expirationDisplay={expirationDisplay}
                      />
                    </>
                  )}
                </Flex>
                <Flex
                  direction='column'
                  align='start'
                  css={{ width: '100%', p: '$modalContentPadding', gap: '$2' }}
                >
                  {transactionError && (
                    <ErrorWell
                      error={transactionError}
                      css={{ width: '100%' }}
                    />
                  )}
                  {stepData && (
                    <>
                      <Flex align='center' css={{ paddingBottom: '.5rem' }}>
                        <Text css={{ textAlign: 'center' }} style='subtitle1'>
                          {stepTitle}
                        </Text>
                        {stepData.currentStep.kind !== StepKind.SIGNATURE && (
                          <CryptoCurrencyIcon
                            chainId={modalChain?.id}
                            css={{
                              padding: '0px .25rem',
                            }}
                            address={wrappedContractAddress}
                          />
                        )}
                      </Flex>
                      {stepData?.currentStep.description && (
                        <Text
                          css={{
                            maxWidth: 395,
                          }}
                          style='body1'
                          color='subtle'
                        >
                          {stepData?.currentStep.description}
                        </Text>
                      )}
                    </>
                  )}
                  {!stepData && (
                    <Flex
                      css={{ height: '100%', py: '$5' }}
                      justify='center'
                      align='center'
                    >
                      <Loader />
                    </Flex>
                  )}
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                {!transactionError && (
                  <Box
                    css={{
                      p: '$modalContentPadding',
                      borderTop: '1px solid $borderColor',
                    }}
                  >
                    <Button css={{ width: '100%', mt: 'auto' }} disabled={true}>
                      {copy.ctaAwaitingApproval}
                    </Button>
                  </Box>
                )}
                {transactionError && (
                  <Flex
                    css={{
                      p: '$modalContentPadding',
                      gap: 10,
                      width: '100%',
                      borderTop: '1px solid $borderColor',
                    }}
                  >
                    <Button
                      color='secondary'
                      css={{ flex: 1 }}
                      onClick={() => {
                        trackEvent('offer_modal_edit_offer_clicked');
                        setBidStep(BidStep.SetPrice);
                      }}
                    >
                      {copy.ctaEditOffer}
                    </Button>
                    <Button css={{ flex: 1 }} onClick={() => placeBid()}>
                      {copy.ctaRetry}
                    </Button>
                  </Flex>
                )}
              </Flex>
            )}

            {bidStep === BidStep.Complete && (
              <Flex direction='column' css={{ height: '100%' }}>
                <Flex
                  direction='column'
                  align='center'
                  css={{
                    width: '100%',
                    p: '$modalContentPadding',
                    gap: '$5',
                    my: '$3',
                  }}
                >
                  <Img
                    src={token?.imageUrlSmall || collection?.config?.imageUrl}
                    alt={token?.name || collection?.name}
                    css={{
                      borderRadius: '$radiusMedium',
                      objectFit: 'cover',
                      height: 94,
                      width: 94,
                      aspectRatio: '1/1',
                      '@sm': {
                        width: 129,
                        height: 129,
                      },
                    }}
                  />
                  <Flex direction='column' css={{ gap: '$2', width: '100%' }}>
                    <Flex justify='between' css={{ gap: '$2' }}>
                      <Text style='subtitle1' color='subtle'>
                        Offer amount
                      </Text>
                      <Text style='subtitle1'>
                        <FormatWrappedCurrency
                          chainId={modalChain?.id}
                          logoWidth={16}
                          textStyle='h6'
                          amount={totalBidAmount}
                          address={currency?.address}
                          decimals={currency?.decimals}
                          symbol={currency?.symbol}
                        />
                      </Text>
                    </Flex>
                    <Flex justify='between' css={{ gap: '$2' }}>
                      <Text style='subtitle1' color='subtle'>
                        Offer expiration
                      </Text>
                      <Text>{expirationDisplay}</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Box css={{ flexGrow: 1 }} />
                <Box
                  css={{
                    p: '$modalContentPadding',
                    borderTop: '1px solid $borderColor',
                  }}
                >
                  {onViewOffers ? (
                    <Button
                      css={{ width: '100%' }}
                      onClick={() => {
                        onViewOffers();
                      }}
                      color='onlyBorder'
                    >
                      {copy.ctaViewOffers}
                    </Button>
                  ) : (
                    <Button
                      css={{ width: '100%' }}
                      onClick={close}
                      color='onlyBorder'
                    >
                      {copy.ctaClose}
                    </Button>
                  )}
                </Box>
              </Flex>
            )}
            {bidStep === BidStep.Unavailable && !loading && (
              <Flex
                direction='column'
                align='center'
                css={{ p: '$4', gap: '$5' }}
              >
                <Box css={{ color: '$neutralSolid', mt: 48 }}>
                  <FontAwesomeIcon
                    icon={faHand}
                    style={{ width: '32px', height: '32px' }}
                  />
                </Box>

                <Text
                  style='h6'
                  css={{ maxWidth: 350, mb: '$3', textAlign: 'center' }}
                >
                  {!collectionBidSupported
                    ? 'This collection does not support placing a collection offer.'
                    : 'Oops, this collection does not support bidding.'}
                </Text>
                <Button css={{ width: '100%' }} onClick={close}>
                  {copy.ctaClose}
                </Button>
              </Flex>
            )}
          </Modal>
        );
      }}
    </BidModalRenderer>
  );
}

BidModal.Custom = BidModalRenderer;
